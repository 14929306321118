import $ from 'jquery';

const ACTIVE_CLASS = 'isActive';

class Nav {
  constructor($el) {
    this.$el = $el;
  }

  init() {
    this.selectElements()
      .bindEventHandlers();

    return this;
  }

  selectElements() {
    this.$button = this.$el.find('.js-nav-button');
    this.$submenu = this.$el.find('.js-nav-submenu');

    return this;
  }

  bindEventHandlers() {
    this.$button.on('click', this.onButtonClick.bind(this));

    return this;
  }

  // EVENT HANDLERS

  onButtonClick(e) {
    let $button = $(e.currentTarget);
    let $menu = $(e.currentTarget).closest('li').find(this.$submenu);

    e.preventDefault();

    this.showHideMenu($menu, $button);
  }

  // HELPER FUNCTIONS

  showHideMenu($menu, $button) {
    if ($menu.hasClass(ACTIVE_CLASS)) {
      this.hideMenu($menu, $button);
    } else {
      this.showMenu($menu, $button);
    }
  }

  showMenu($menu, $button) {
    $menu.addClass(ACTIVE_CLASS);
    $button.addClass(ACTIVE_CLASS);
  }

  hideMenu($menu, $button) {
    $menu.removeClass(ACTIVE_CLASS);
    $button.removeClass(ACTIVE_CLASS);
  }
}

export default Nav;
