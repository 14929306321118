import $ from 'jquery';

class Search {
  constructor($el) {
    this.$el = $el;
  }

  init() {
    this.selectElements()
      .bindEventHandlers();

    return this;
  }

  selectElements() {
    this.$button = this.$el.find('.js-search-button');
    this.$searchForm = this.$el.find('.js-search-bar-header');

    return this;
  }

  bindEventHandlers() {
    this.$button.on('click', this.onButtonClick.bind(this));

    return this;
  }

  // EVENT HANDLERS

  onButtonClick(e) {
    let $searchBar = this.$searchForm;

    e.preventDefault();

    this.showHideMenu($searchBar);
  }

  // HELPER FUNCTIONS

  showHideMenu($searchBar) {
    if ($searchBar.hasClass('isActive')) {
      this.hideMenu($searchBar);
    } else {
      this.showMenu($searchBar);
    }
  }

  showMenu($searchBar) {
    $searchBar.addClass('isActive');
  }

  hideMenu($searchBar) {
    $searchBar.removeClass('isActive');
  }
}

export default Search;
