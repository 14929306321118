/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'stylesheets/application'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import $ from 'jquery';

import Nav from '../Nav';
import MobileNav from '../MobileNav';
import Search from '../Search';
import { Player } from 'apm-html5-player';

// Add image assets to webpacker, not Sprockets
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

// Initialize menu stuff
if ($('.js-nav').length) {
  new Nav($('.js-nav')).init();
}

if ($('.js-mobile-nav').length) {
  new MobileNav($('.js-mobile-nav')).init();
}

if ($('.js-search').length) {
  new Search($('.js-search')).init();
}

// The DOM element container
const playerElement = document.querySelector('.js-player');
// Create new instance of the Player class
const player = new Player(playerElement);

// Don't forget to initialize
player.init();
